// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"
// gatsby-browser.js

// importing the prism color scheme
require("prismjs/themes/prism-coy.min.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")